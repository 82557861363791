<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="pb-0"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="100"
            />
          </div>
          <div class="ml-7 mb-3 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="32"
            color="white"
            @click="toggleAddEditViewDistrictModal({ show: false })"
            style="cursor: pointer; margin-top: -80px; margin-right: -12px"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="districtForm" lazy-validation>
            <v-row no-gutters style="margin-top: 20px">
              <v-col v-if="previewImage" cols="6">
                <img :src="previewImage" alt="" size="100%" class="logo" />
              </v-col>
              <v-col
                v-if="!previewImage && (type === 'edit' || type === 'view')"
                cols="6"
                class="px-5"
              >
                <v-avatar color="#20BA9E" size="100">
                  <span class="user-initials-text">
                    {{ districtName.substring(0, 2).toUpperCase() }}
                  </span>
                </v-avatar>
              </v-col>
              <v-col class="px-4 mt-8" cols="6">
                <div v-if="type == 'add' && !previewImage">
                  <v-btn
                    text
                    dark
                    color="#38227A"
                    class="text-capitalize px-5"
                    style="
                      border: 1.5px solid #38227a;
                      border-radius: 10px;
                      font-family: Lato;
                      font-weight: 600;
                    "
                    @click="$refs.fileInput.click()"
                    height="37px"
                    ><span>upload logo</span></v-btn
                  >
                </div>
                <div
                  v-if="
                    type == 'edit' ||
                    (previewImage && (type === 'add' || type === 'edit'))
                  "
                  style="float: right"
                >
                  <v-btn
                    text
                    dark
                    color="#38227A"
                    class="text-capitalize px-5"
                    style="
                      border: 1.5px solid #38227a;
                      border-radius: 10px;
                      font-family: Lato;
                      font-weight: 600;
                    "
                    @click="$refs.fileInput.click()"
                    height="37px"
                    ><span>change logo</span></v-btn
                  >
                </div>
                <v-file-input
                  accept="image/png,image/jpeg,image/svg+xml"
                  label="upload logo"
                  prepend-icon
                  outlined
                  color="#7253CF"
                  dense
                  class="file-input"
                  id="inputIdD"
                  @change="onUploadLogoChange"
                  style="display: none"
                >
                </v-file-input>
                <label
                  for="inputIdD"
                  ref="fileInput"
                  style="display: none"
                ></label>
              </v-col>
              <p v-if="!previewImage && type == 'add'" class="logo-des">
                Please upload a logo for the district,file size should be less
                than 10 MB
              </p>
            </v-row>
            <v-row no-gutters class="mt-8" justify="center">
              <v-col cols="12" class="px-2">
                <v-text-field
                  label="District Name"
                  outlined
                  dense
                  v-model="districtName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-autocomplete
                  label="Event Support"
                  outlined
                  dense
                  v-model="event_support"
                  :items="eventSupportList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-autocomplete
                  :rules="[rules.required]"
                  v-model="salesRep"
                  :items="salesRepList"
                  label="Sales REP"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="City"
                  outlined
                  dense
                  v-model="city"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-autocomplete
                  label="State"
                  outlined
                  dense
                  :rules="[rules.required]"
                  v-model="state"
                  :items="statesList"
                  class="formFields"
                  item-text="state_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-autocomplete>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Contact Person Name"
                  outlined
                  dense
                  class="formFields"
                  :rules="[rules.required]"
                  v-model="contactPersonName"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Role"
                  outlined
                  dense
                  class="formFields"
                  :rules="[rules.required]"
                  v-model="role"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                ></v-text-field>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="email"
                  @keyup="getSchoolEmailList"
                  label="Contact Person Email"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
                <input
                  type="hidden"
                  v-model="hiddenValue1"
                  name="hiddenValue1"
                />
                <input
                  type="hidden"
                  v-model="hiddenValue2"
                  name="hiddenValue2"
                />
                <ul>
                  <li v-for="emailList in emailList" :key="emailList.id">
                    <button
                      @click="
                        selectEmail(
                          emailList.username,
                          emailList.user_type,
                          emailList.sub_user_type,
                          $event
                        )
                      "
                    >
                      {{ emailList.username }}
                    </button>
                  </li>
                </ul>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Mobile"
                  outlined
                  dense
                  class="formFields"
                  v-model="mobile"
                  type="number"
                  :rules="[rules.mobile]"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="px-3">
                <v-textarea
                  outlined
                  dense
                  v-model="shippingAddress"
                  :rules="[rules.required]"
                  label="Shipping Address"
                  color="#7253CF"
                  class="formFields"
                  row-height="8"
                  :disabled="formLoading"
                >
                </v-textarea>
              </v-col>
              <v-col class="px-3" cols="12">
                <v-textarea
                  outlined
                  dense
                  v-model="SpecialMessage"
                  label="Special Message"
                  color="#7253CF"
                  class="formFields"
                  row-height="14"
                  :disabled="formLoading"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-7 px-9" style="background-color: #ffffff">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText px-9"
            height="43px"
            dark
            color="#38227A"
            @click="submitDistrictForm"
          >
            <span>Add District</span>
          </v-btn>
          <v-btn
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText px-15"
            height="43px"
            @click="submitDistrictForm"
            dark
            color="#38227A"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  API_MASTER_GET_STATES_LIST,
  API_USER_GET_SALES_REP_LIST,
  API_ADMIN_ADD_DISTRICT,
  API_ADMIN_GET_DISTRICT_DETAIL,
  API_ADMIN_UPDATE_DISTRICT,
  API_MASTER_GET_MESSAGE,
  API_USER_GET_EVENT_SUPPORT_LIST,
  API_SCHOOL_USER_CONNECT_LIST,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import RULES from "@/common/fieldRules";

export default {
  name: "AddEditViewDistrictModal",
  components: {},
  props: ["DistrictFormData"],
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      previewImage: null,
      LogoImgFile: {},
      districtName: "",
      salesRep: null,
      salesRepList: [],
      city: "",
      state: null,
      statesList: [],
      contactPersonName: "",
      role: "",
      email: "",
      mobile: null,
      shippingAddress: "",
      district_name: "",
      cont_per_name: "",
      SpecialMessage: "",
      event_support: "",
      eventSupportList: [],
      subUserType: "",
      userTypes: "",
      emailList: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "districtManagement/getShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewDistrictModal({ show: value });
      },
    },
    type() {
      return this.$store.state.districtManagement.addEditViewModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "District Added";
      } else {
        return "District Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add new district";
        case "edit":
          return "Edit District";
        case "view":
          return "District details";
        default:
          return "";
      }
    },
  },
  watch: {
    /**
     * Watch modal show hide
     */
    show(value) {
      if (value) {
        // Modal open callback
        setTimeout(() => {
          this.modalOpenCallback();
        }, 100);
      } else {
        // Modal close callback
        this.modalCloseCallback();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewDistrictModal: "districtManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    onUploadLogoChange(payload) {
      // const file = payload.target.files[0]; // use it in case of normal HTML input
      let file = payload; // in case vuetify file input
      console.log(file, "logoFile");
      console.log(file.size);
      console.log(file.type);
      if (file.size < 1000000) {
        console.log(file.type);
        this.LogoImgFile = payload;
        if (file) {
          this.previewImage = URL.createObjectURL(file);
          URL.revokeObjectURL(file); // free memory
        } else {
          this.previewImage = null;
        }
      } else {
        console.log("image validation error");
        this.showToast({
          message: "Logo file size must be less than 10 MB."
,
          color: "e",
        });
      }

      // console.log(this.previewImage, "previewImage");
      // console.log(this.LogoImgFile, "LogoImgFile");
    },
    /**
     * Callback handler on open of modal
     */
    modalOpenCallback() {
      console.log("modalOpenCallback");
      this.loading = true;
      this.getStateList();
      this.getEventSupportList();
    },
    /**
     * Callback handler on close of modal
     */
    modalCloseCallback() {
      this.previewImage = null;
      this.LogoImgFile = {};
      this.districtName = "";
      this.salesRep = null;
      this.salesRepList = [];
      this.city = "";
      this.state = -1;
      this.statesList = [];
      this.contactPersonName = "";
      this.role = "";
      this.email = "";
      this.mobile = null;
      this.loading = false;
      this.formLoading = false;
      this.shippingAddress = "";
      this.SpecialMessage = "";
    },
    getSpecialMessage() {
      const successHandler = (res) => {
        // console.log(res,"special message")
        this.SpecialMessage = res.data.special_message_list[0].message;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["message_for"] = "district";
      formData["message_type"] = "special_message";
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    getEventSupportList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.eventSupportList = data.event_support_staff_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_USER_GET_EVENT_SUPPORT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /**
     * GET State List
     */
    getStateList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        console.log(data.state_list);
        self.statesList = data.state_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getSalesRepList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_MASTER_GET_STATES_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /**
     * GET Sales-Rep List
     */
    getSalesRepList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        console.log(data.sales_rep_list);
        self.salesRepList = data.sales_rep_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        if (self.type === "view" || self.type === "edit") {
          self.getDistrictDetails();
        } else {
          self.getSpecialMessage();
        }
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    selectEmail(email, additionalValue1, additionalValue2, event) {
      // Prevent the default button click behavior (form submission)
      event.preventDefault();

      // Set the selected email in the input
      this.email = email;
      this.hiddenValue1 = additionalValue1;
      this.hiddenValue2 = additionalValue2;
      // Clear the search results
      this.emailList = [];
    },
    /**
     * GET District details
     */
    getDistrictDetails() {
      const self = this;

      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data.district_detail);

        // this.previewImage = null;
        this.districtName = data.district_detail.district_name;
        this.salesRep = data.district_detail.sales_rep;
        this.event_support = data.district_detail.event_support;
        this.city = data.district_detail.city_name;
        this.contactPersonName = data.district_detail.user_name;
        this.role = data.district_detail.role;

        this.email = data.district_detail.user_email;
        this.mobile = data.district_detail.user_phone;
        this.state = data.district_detail.state_id;
        this.previewImage = data.district_detail.logo_url;
        this.shippingAddress = data.district_detail.shipping_address;
        this.SpecialMessage = data.district_detail.special_message;

        self.loading = false;
      };

      const failureHandler = (res) => {
        console.log("Failure ", res);
        self.loading = false;
      };

      let formData = {};
      formData["district_id"] =
        this.$store.state.districtManagement.addEditViewModal.districtID;
      console.log({ formData });

      /**
       * API Call for ADD District
       */
      Axios.request_GET(
        API_ADMIN_GET_DISTRICT_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    /**
     * Form submit handler
     * @param e
     */
    submitDistrictForm() {
      if (this.$refs.districtForm.validate()) {
        const self = this;
        self.formLoading = true;
        console.log("Form validated");

        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          self.formLoading = false;
          self.toggleAddEditViewDistrictModal({ show: false });
          //  self.$emit("reload");
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };

        const failureHandler = (res) => {
          console.log("Failure ", res);
          this.showToast({
            message: res,
            color: "e",
          });
          self.formLoading = false;
        };

        let formData = new FormData();
        formData.append("district_name", this.districtName);
        formData.append("user_name", this.contactPersonName);
        formData.append("user_email", this.email);
        formData.append("user_type", this.hiddenValue1);
        formData.append("sub_user_type", this.hiddenValue2);
        formData.append("user_phone", this.mobile);
        formData.append("role", this.role);
        formData.append("city_name", this.city);
        formData.append("state_id", this.state);
        formData.append("sales_rep", this.salesRep);
        formData.append("shipping_address", this.shippingAddress);
        formData.append("special_message", this.SpecialMessage);
        formData.append("event_support", this.event_support);

        if (this.LogoImgFile instanceof File) {
          formData.append("logo", this.LogoImgFile);
        }

        if (this.type === "edit") {
          formData.append(
            "district_id",
            this.$store.state.districtManagement.addEditViewModal.districtID
          );
        }
        if (this.type === "add") {
          /**
           * API Call for ADD District
           */
          Axios.request_POST(
            API_ADMIN_ADD_DISTRICT,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          /**
           * API Call for UPDATE District
           */
          Axios.request_PATCH(
            API_ADMIN_UPDATE_DISTRICT,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    getSchoolEmailList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.state_list);
        self.emailList = data.user;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {};

      const formJson = {};
      formJson.search = this.email;
      return Axios.request_GET(
        API_SCHOOL_USER_CONNECT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}
.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-left: 13px;
  margin-top: 5px;
  font-style: italic;
  color: #404040;
  line-height: 17px;
}

img.logo {
  margin-left: 20px;
  max-height: 100px;
  max-width: 200px;
}
.v-btn.submit-btn {
  margin-left: 150px;
  margin-bottom: 25px;
}
.v-btn.submit-btn-edit {
  margin-left: 215px;
  margin-bottom: 25px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
